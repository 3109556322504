import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_INFO_KEY,
  NOTIF_INFO_KEY,
} from "./../helpers/constant";
import Cookies from "js-cookie";

export const setUserData = (value) => {
  // localStorage.setItem(ACCESS_TOKEN_KEY, value.access_token);
  // localStorage.setItem(REFRESH_TOKEN_KEY, value.refresh_token);
  // Cookies.set(ACCESS_TOKEN_KEY, value.access_token, { expires: 7 }); // Expires in 7 days
  // Cookies.set(REFRESH_TOKEN_KEY, value.refresh_token, { expires: 7 });
  Cookies.set(ACCESS_TOKEN_KEY, value.access_token, {
    expires: 7, // Kadaluarsa dalam 7 hari
    path: "/", // Tersedia di seluruh domain
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true, // Hanya dikirim melalui HTTPS
    sameSite: "Strict", // Perlindungan CSRF
  });

  Cookies.set(REFRESH_TOKEN_KEY, value.refresh_token, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
};

export const setUserInfo = (data) => {
  // localStorage.setItem(USER_INFO_KEY, JSON.stringify(data));
  Cookies.set(USER_INFO_KEY, JSON.stringify(data), { expires: 7 });
};
export const setNotifInfo = (data) => {
  // localStorage.setItem(NOTIF_INFO_KEY, JSON.stringify(data));
  Cookies.set(NOTIF_INFO_KEY, JSON.stringify(data), { expires: 7 });
};
export const getUserData = () => {
  // let access_token = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
  // let refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY) || null;
  let access_token = Cookies.get(ACCESS_TOKEN_KEY) || null;
  let refresh_token = Cookies.get(REFRESH_TOKEN_KEY) || null;
  return {
    access_token,
    refresh_token,
  };
};
export const setAccessToken = (access_token) => {
  // localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  // Cookies.set(ACCESS_TOKEN_KEY, access_token, { expires: 7 });
  Cookies.set(ACCESS_TOKEN_KEY, access_token, {
    expires: 7, // Kadaluarsa dalam 7 hari
    path: "/", // Tersedia di seluruh domain
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true, // Hanya dikirim melalui HTTPS
    sameSite: "Strict", // Perlindungan CSRF
  });
};
export const setRefreshToken = (refresh_token) => {
  // localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  // Cookies.set(REFRESH_TOKEN_KEY, refresh_token, { expires: 7 });
  Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
};
export const removeUserData = () => {
  // localStorage.removeItem(ACCESS_TOKEN_KEY);
  // localStorage.removeItem(REFRESH_TOKEN_KEY);
  Cookies.remove(ACCESS_TOKEN_KEY, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
  Cookies.remove(REFRESH_TOKEN_KEY, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
};
export const getAccessToken = () => {
  // return localStorage.getItem(ACCESS_TOKEN_KEY) || null;
  return Cookies.get(ACCESS_TOKEN_KEY) || null;
};
export const getRefreshToken = () => {
  // return localStorage.getItem(REFRESH_TOKEN_KEY) || null;
  return Cookies.get(REFRESH_TOKEN_KEY) || null;
};
export const getUserInfo = () => {
  // let user_info = localStorage.getItem(USER_INFO_KEY) || null;
  let user_info = Cookies.get(USER_INFO_KEY) || null;
  if (user_info) {
    return JSON.parse(user_info);
  }
  return null;
};

export const getNotifInfo = () => {
  // let notif_info = localStorage.getItem(NOTIF_INFO_KEY) || null;
  let notif_info = Cookies.get(NOTIF_INFO_KEY) || null;
  if (notif_info) {
    return JSON.parse(notif_info);
  }
  return null;
};
export const isAuthenticate = () => {
  // let access_token = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
  // let refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY) || null;
  let access_token = Cookies.get(ACCESS_TOKEN_KEY) || null;
  let refresh_token = Cookies.get(REFRESH_TOKEN_KEY) || null;
  return access_token && refresh_token ? true : false;
};
export const clearAll = () => {
  // localStorage.clear();
  Cookies.remove(ACCESS_TOKEN_KEY, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
  Cookies.remove(REFRESH_TOKEN_KEY, {
    expires: 7,
    path: "/",
    domain: ".rsmajalengka.my.id", // Ganti dengan domain yang diinginkan
    secure: true,
    sameSite: "Strict",
  });
  Cookies.remove(USER_INFO_KEY);
  Cookies.remove(NOTIF_INFO_KEY);
};
export const useStorage = () => {
  return {
    isAuthenticate,
    clearAll,
    getUserInfo,
    getRefreshToken,
    setUserData,
    getNotifInfo,
    setNotifInfo,
    getUserData,
    setAccessToken,
    setRefreshToken,
    removeUserData,
    getAccessToken,
    setUserInfo,
  };
};
