export const ACCESS_TOKEN_KEY = "HajYYatdg";
export const REFRESH_TOKEN_KEY = "Jkapwy617";
export const USER_INFO_KEY = "Kdjtqyyak";
export const NOTIF_INFO_KEY = "yyjkkyyak";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API = process.env.REACT_APP_BASE_URL_DEV + "hris/";

export const darkColor = "#2b334c";
export const lighGrayColor = "#fbfbfb";
