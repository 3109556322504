import React from "react";
import PropTypes from "prop-types";

const LoadingBar = ({ loading }) => {
  return loading ? (
    <div className="center-div-loading">
      <div className="linear-progress-material">
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
      </div>
    </div>
  ) : null;
};

LoadingBar.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingBar;
